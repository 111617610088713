
        import * as module0 from '../components/audio_player_element.js.rb';import * as module1 from '../components/bank/bank_editor_element.js.rb';import * as module2 from '../components/bank/comment_elements.js.rb';import * as module3 from '../components/download_analytics_element.js.rb';import * as module4 from '../components/forms/autogrow_textarea_element.js.rb';import * as module5 from '../components/forms/bulk_uploader_element.js.rb';import * as module6 from '../components/forms/new_comment_element.js.rb';import * as module7 from '../components/forms/plugin_editor_element.js.rb';import * as module8 from '../components/forms/soundclip_uploader_element.js.rb';import * as module9 from '../components/forms/toggle_form_submit_element.js.rb';import * as module10 from '../components/forms/trix_element.js.rb';import * as module11 from '../components/pg_button.heartml';import * as module12 from '../components/pg_dialog.heartml';import * as module13 from '../components/pg_link.heartml';import * as module14 from '../components/rating_stars_element.js.rb';import * as module15 from '../components/save_context_element.js.rb';import * as module16 from '../components/soundclip_element.js.rb';import * as module17 from '../components/topbar_element.js.rb'
        const modules = {
            "../components/audio_player_element.js.rb": module0,
            "../components/bank/bank_editor_element.js.rb": module1,
            "../components/bank/comment_elements.js.rb": module2,
            "../components/download_analytics_element.js.rb": module3,
            "../components/forms/autogrow_textarea_element.js.rb": module4,
            "../components/forms/bulk_uploader_element.js.rb": module5,
            "../components/forms/new_comment_element.js.rb": module6,
            "../components/forms/plugin_editor_element.js.rb": module7,
            "../components/forms/soundclip_uploader_element.js.rb": module8,
            "../components/forms/toggle_form_submit_element.js.rb": module9,
            "../components/forms/trix_element.js.rb": module10,
            "../components/pg_button.heartml": module11,
            "../components/pg_dialog.heartml": module12,
            "../components/pg_link.heartml": module13,
            "../components/rating_stars_element.js.rb": module14,
            "../components/save_context_element.js.rb": module15,
            "../components/soundclip_element.js.rb": module16,
            "../components/topbar_element.js.rb": module17,
        };
        export default modules;
      