class BankComment < HeartElement
  self.template = html <<~HTML
    <slot></slot>
  HTML

  self.styles = css <<~CSS
    :host {
      --indent-spacing: 25px;

      display: block;
      contain: content;
      padding-inline: 1px;
      padding-block: 5px;
      margin-block-end: 10px;
      margin-inline-start: calc(var(--indent-level) * var(--indent-spacing));
    }

    @media (max-width: 500px) {
      :host {
        --indent-spacing: 18px;
      }
    }

    :host::after {
      content: "";
      clear: both;
      display: table;
    }

    ::slotted(time) {
      float: right;
      font-size: 0.75em;
      opacity: 0.5;
      text-transform: uppercase;
      margin-block-start: var(--time-inset, 22px);
    }
  CSS

  self.queries = {
    light: {
      author: "bank-comment-author",
    },
  }

  define "bank-comment"

  def comment_id
    self.get_attribute "comment-id"
  end

  def new_form
    form_id = self.get_attribute("form-id")
    document.query_selector("##{form_id}")
  end

  def new_form_element
    document.query_selector("#comments-section new-comment")
  end
end

class BankCommentAuthor < HeartElement
  self.template = html <<~HTML
    <img src="" host-effect="@src = .avatar" class="author-avatar" />
    <div class="author-name">
      <slot></slot>
    </div>
  HTML

  self.styles = css <<~CSS
    .author-avatar {
      float: left;
      width: var(--avatar-size, 70px);
      height: var(--avatar-size, 70px);
      border: var(--avatar-border, 1px solid #ddd);
      border-radius: var(--avatar-radius, 50%);
    }

    .author-name {
      color: var(--author-color, white);
      font-weight: var(--author-weight, bold);
      margin-block-start: var(--author-gap, 24px);
      margin-inline-start: var(--body-indent, 90px);
    }
  CSS

  self.properties = {
    avatar: {},
  }

  self.declarative_effects = { shadow: true }

  define "bank-comment-author"

  def start()
    self.avatar = ""
  end
end

class BankCommentVoting < HeartElement
  def self._vote_button_template(direction)
    <<~HTML
      <div>
        <button
          data-direction="#{direction}"
          host-effect="setButtonClasses('#{direction}')"
          host-event="click#vote"
        >
          <sl-icon library="linea" name="arrows/#{direction}"></sl-icon>
        </button>
      </div>
    HTML
  end

  self.template = html <<~HTML
    #{self._vote_button_template(:up)}
    <div id="tally" host-effect=".tallyLabel">0</div>
    #{self._vote_button_template(:down)}
  HTML

  self.styles = css <<~CSS
    :host {
      float: left;
      clear: left;
      padding: 10px;
      padding-block-end: 0;
      margin-top: 2px;
      line-height: 1;
      text-align: center;
      width: calc(var(--avatar-size, 70px) - 20px)
    }

    button {
      color: inherit;
      background: transparent;
      border: none;
      padding: 0;
      cursor: pointer;
      font-size: inherit;
    }
    button:hover {
      background: rgba(255,255,255,0.3);
    }
    button.active {
      color: orange;
    }
    button.noaction {
      opacity: 0.5;
    }

    #tally {
      color: var(--tally-color, white);
      font-size: 0.9rem;
      font-weight: bold;
      margin-block-start: -2px;
      cursor: default;
      margin-block-end: 2px;
      text-align: center;
    }

    sl-icon {
      vertical-align: 0.1em;
    }
  CSS

  self.properties = {
    voted: {},
    vote_label: { attribute: "votelabel" },
    tally: {},
    action: {},
  }

  self.declarative_effects = { shadow: true }
  self.declarative_events = %i(click)

  define "bank-comment-voting"

  def start()
    self.voted = ""
    self.vote_label = ""
    self.tally = 0
    self.action = ""
  end

  async def vote(_, button)
    return unless self.action

    up_or_down = button.dataset.direction

    response = await Daniel.post(self.action, "up_or_down" => up_or_down)
    if response.ok?
      self.tally = (await response.text()).to_i
      self.voted = self.voted == up_or_down ? "" : up_or_down
    else
      console.warn(response)
      Toaster.raise(
        "exclamation-octagon",
        "Oops! Something glitched on the server. Try again later?",
        variant: :danger
      )
    end
  end

  def set_button_classes(node, direction)
    node.class_list.toggle :active, self.voted == direction
    node.class_list.toggle :noaction, !self.action
  end

  def tally_label
    return self.tally if self.voted

    case self.tally
    when 0, 1, -1
      self.vote_label
    else
      self.tally
    end
  end
end

class BankCommentBody < HeartElement
  self.styles = css <<~CSS
    :host {
      display: block;
      margin-block-start: var(--comment-gap, 20px);
      margin-inline-start: var(--body-indent, 90px);
    }
  CSS

  self.template = html <<~HTML
    <slot></slot>
  HTML

  define "bank-comment-body"
end

class BankCommentActions < HeartElement
  self.template = html <<~HTML
    <pg-button size="small" host-effect="$show(.replyAction)" host-event="click#reply"><slot name="reply"></slot></pg-button>
    <pg-button size="small" host-effect="$show(.editAction)" host-event="click#edit"><slot name="edit"></slot></pg-button>
  HTML

  self.styles = css <<~CSS
    :host {
      display: flex;
      gap: var(--spacing-3);
      margin-block-start: var(--actions-gap, 20px);
      margin-inline-start: var(--body-indent, 90px);
    }

    [hidden] {
      display: none;
    }
  CSS

  self.properties = {
    reply_action: { attribute: "reply-action" },
    edit_action: { attribute: "edit-action" },
  }

  self.queries = {
    light: {
      comment_body: 'textarea[name="comment_body"]',
    },
  }

  self.declarative_effects = { shadow: true }
  self.declarative_events = %i(click)

  define "bank-comment-actions"

  def start()
    self.replyAction = false
    self.editAction = false
  end

  def reply()
    new_form.reset()
    new_form.start_editing(
      context: "reply",
      comment_id: comment.comment_id,
      replying_to: comment.author.text_content
    )
  end

  def edit()
    new_form.reset()
    new_form.start_editing(
      context: "edit",
      comment_id: comment.comment_id,
      body: self.comment_body.value
    )
  end

  def comment = self.closest("bank-comment")
  def new_form = comment.new_form_element
end
