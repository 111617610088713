
          var import_meta_document = new DocumentFragment()
        
            const htmlFrag = "<body>" + "<dialog>\n  <dialog-interior>\n    <header>\n      <slot name=\"header\"></slot>\n      <button id=\"close-dialog\" aria-label=\"Close Dialog\">\n        <sl-icon library=\"linea\" name=\"arrows/remove\"></sl-icon>\n      </button>\n    </header>\n    <dialog-content>\n      <slot></slot>\n    </dialog-content>\n    <blockquote><slot name=\"header\"></slot></blockquote>\n    <footer>\n      <slot name=\"footer\"></slot>\n    </footer>\n  </dialog-interior>\n</dialog>\n\n<style>\n  dialog {\n    background: linear-gradient(var(--color-darkest-blue), var(--color-darker-blue));\n    border: var(--callout-border);\n    border-radius: var(--box-radius);\n    box-shadow: 0px 15px 50px -10px rgba(15,0,30,0.5);\n    padding: 0;\n    min-width: 350px;\n    max-width: 40rem;\n  }\n\n  dialog-interior {\n    display: block;\n    padding: var(--spacing-5);\n  }\n\n  header {\n    display: flex;\n    justify-content: space-between;\n  }\n\n  button#close-dialog {\n    padding: 5px;\n    font-size: 2.5em;\n    color: white;\n    background: transparent;\n    border: none;\n    cursor: pointer;\n    margin-block-start: calc(0rem - var(--spacing-3));\n    margin-inline-end: calc(0rem - var(--spacing-3));\n  }\n\n  footer {\n    text-align: center;\n  }\n\n  ::slotted(fieldset) {\n    display: grid;\n    gap: var(--spacing-3);\n  }\n</style>\n\n" + "</body>"
            const fragment = new DOMParser().parseFromString(htmlFrag, 'text/html')
            import_meta_document.append(...fragment.body.childNodes)
          
          
  import { ShadowRootable } from "turbo-shadow"

  class PgDialog extends ShadowRootable(HTMLElement) {
    static {
      customElements.define("pg-dialog", this)
    }

    connectedCallback() {
      console.log("here!")
      this.addEventListener("click", this)
    }

    disconnectedCallback() {
      this.removeEventListener("click", this)
    }

    /**
     * @param event {Event}
     */
    handleEvent(event) {
      if (event.type === "click") {
        if (event.composedPath()[0] === this.dialogElement) {
          // backdrop clicked
          this.close()
        } else if (event.composedPath().some(element => element.id === "close-dialog")) {
          this.close()
        }
      }
    }

    /**
     * @return {HTMLDialogElement}
     */
    get dialogElement() {
      return this.shadowRoot.querySelector("dialog")
    }

    showModal() {
      this.dialogElement.showModal()
    }

    close() {
      this.dialogElement.close()
    }

    get open() {
      return this.dialogElement.open
    }

    set open(val) {
      this.dialogElement.open = val
    }
  }

          export default import_meta_document
        